import { render, staticRenderFns } from "./sjlistdetail.vue?vue&type=template&id=6604ee7e&scoped=true"
import script from "./sjlistdetail.vue?vue&type=script&lang=js"
export * from "./sjlistdetail.vue?vue&type=script&lang=js"
import style0 from "./sjlistdetail.vue?vue&type=style&index=0&id=6604ee7e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6604ee7e",
  null
  
)

component.options.__file = "sjlistdetail.vue"
export default component.exports